export function handleButtonClick(buttonContainerId, inputElementName) {
  const buttonContainer = document.getElementById(buttonContainerId);
  const inputElement = document.querySelector(`input[name="${inputElementName}"]`); // Select the input element

  // Check the initial value of the input element
  const initialValue = inputElement.value;
  if (initialValue === 'true') {
      document.getElementById('collectionButton').classList.add('active', 'yellow');
  } else {
      if (initialValue === '') {
          inputElement.value = 'false';
      }
      document.getElementById('singleButton').classList.add('active', 'blue');
  }

  buttonContainer.addEventListener('click', function(event) {
      event.preventDefault();
      if (event.target.classList.contains('button')) {
          const activeButton = document.querySelector('.button.active');
          if (activeButton) {
              activeButton.classList.remove('active');
              // Remove color class from the previously active button
              if (activeButton.id === 'singleButton') {
                  activeButton.classList.remove('blue');
              } else if (activeButton.id === 'collectionButton') {
                  activeButton.classList.remove('yellow');
              }
          }
          event.target.classList.add('active');
          // Add color class to the newly active button
          if (event.target.id === 'singleButton') {
              event.target.classList.add('blue');
              inputElement.value = 'false'; // Set input value to 'false' when the button is blue
          } else if (event.target.id === 'collectionButton') {
              event.target.classList.add('yellow');
              inputElement.value = 'true'; // Set input value to 'true' when the button is yellow
          }
      }
  });
}
export function applyDescriptor(inputElementName) {
  // Get the input elements
  const inputElements = document.getElementsByName(inputElementName);

  // Get the descriptor of the 'value' property
  const descriptor = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');

  // Store the original 'set' method
  const originalSet = descriptor.set;

  // Override the 'set' method of the descriptor
  descriptor.set = function(value) {
      originalSet.call(this, value);

      // Trigger the 'input' event
      const event = new Event('input', { bubbles: true });
      this.dispatchEvent(event);
  };

  // Apply the descriptor to each input element
  inputElements.forEach(inputElement => {
      Object.defineProperty(inputElement, 'value', descriptor);

      // Add an event listener for the 'input' event
      inputElement.addEventListener('input', function(e) {
          // Update the 'value' attribute
          this.setAttribute('value', e.target.value);
      });
  });
}
export async function fetchFillComarc() {
  let cid = document.getElementById('inputCobissId').value;
  let uid = document.getElementById('currentUserId').value;
	if (!cid) {
		alert("Prosim vstavi COBISS ID");
		return;
	}
	if (!uid) {
        alert("Prosim prijavi se");
        return;
	}

	try {
		const response = await fetch(`/cobiss_data`, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify({cid: cid, uid: uid}),
		})
		.then(response => response.json())
		.then(data => {
			console.log(data)
			if (data.status_code == 201) {
				window.location.href = data.redirect_url;
				//console.log(data);
			} else {
				alert(data.message);
				//console.log(data);
			}
		});
	} catch (error) {
    const errorMsg = {
      message: "Something ain't alright there Boss ... :/"
    };
    console.log(JSON.stringify(errorMsg));
	}
}
export async function fetchFillNuk() {
  let urn = document.getElementById('inputNukUrnNew').value;
  let uid = document.getElementById('currentUserId').value;
  const preloader = document.getElementById('preloader');
  const content = document.getElementById('deposit-form');
  //const result = document.getElementById('result');
  
  if (!urn) {
    alert("Prosim vstavi URN");
    return;
  }
  
  if (!uid) {
    alert("Prosim prijavi se");
    return;
  }
  
  try {

    // Show preloader
    preloader.classList.add('show');
    preloader.classList.remove('hide');
    content.style.display = 'none';

    //await new Promise(resolve => setTimeout(resolve, 10000)); // 10 seconds delay

    const response = await fetch(`/nuk_data`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ urn: urn, uid: uid }),
    });
    
    const data = await response.json();
    
    if (response.ok) {
      if (data.status_code == 201) {
        window.location.href = data.redirect_url;
        //console.log(data.message);
        //console.log(data);
      } else {
        console.log(data.message);
      }
    } else {
      console.log(`Error: ${response.status} - ${data.message}`);
    }
  } catch (error) {
    console.log(JSON.stringify({ message: "Something ain't alright there Boss ... :/" }));
  } finally {
    // Hide preloader and show content
    preloader.classList.add('hide');
    setTimeout(() => {
      preloader.classList.remove('show');
      content.style.display = 'block';
    }, 1000); // Match this duration with the CSS transition duration
    
  }
}
import { getUUID } from './get_uuid';
import { handleButtonClick } from './handle_button_click';
import { applyDescriptor } from './apply_descriptor';
import { fetchFillComarc } from './fetch_fill_comarc';
import { fetchFillNuk } from './fetch_fill_nuk';
import { headerScroll } from './header_scroll';
import { vocabs } from './vocabs';

let fetchedSkenerData = null;
async function fetchSkenerData() {
  const urn = document.getElementById('inputNukUrn').value;
  if (!urn) {
    alert("Prosim vstavi URN");
    return;
  }
  try {
    const response = await fetch(`/get_data_from_nuk?urn=${urn}`);
    const data = await response.json();
    //const container = document.getElementById('fetchSkenerPlaceholder');
    //container.innerHTML = '';
    //jsonToHtmlNuk(data,container);
    fetchedSkenerData = data;
    console.log(`${urn} data from DM (Skener) fetched.`);
    console.log(fetchedSkenerData);
    return fetchedSkenerData;
  } catch (error) {
    alert("Invalid input.");
    console.log(`Error fetching data: ${error}`);
  }
}


document.addEventListener('DOMContentLoaded', () => {
  const isDeposit = document.getElementById('deposit-form');
  const isFrontScroll = document.querySelector('.header.frontpage .outer-navbar');
  if (isDeposit) {
    const actions = [
      {id: "fetchFillComarc", action: fetchFillComarc},
      //{id: "fetchFillNuk", action: fetchFillNuk}
      {id: "fetchFillNuk", action: fetchSkenerData},
      {id: "fetchFillNukNew", action: fetchFillNuk}
    ]
    actions.forEach(({ id, action }) => {
      document.getElementById(id).addEventListener("click", function(event) {
        event.preventDefault();
        action();
        });
    });
    const names = [
      'metadata.title',
      'custom_fields.UUID', 
      'custom_fields.is_collection',
      'custom_fields.sadje',
      'custom_fields.europeana',
      'custom_fields.enumerate'
    ];
    names.forEach(name => applyDescriptor(name));
    handleButtonClick('singleOrCollection', 'custom_fields.is_collection');
    const inputUUID = document.querySelector('input[name="custom_fields.UUID"]');
    getUUID(inputUUID);
    const vocabConfigs = [
        {
            dropdownId: "europeanaDropdown",
            inputBoxName: "custom_fields.europeana",
            apiUrl: "/api/vocabularies/europeana",
            issuedMenuDiv: null
        },
        {
            dropdownId: "enumerateDropdown",
            inputBoxName: "custom_fields.enumerate",
            apiUrl: "/api/vocabularies/enumerate",
            issuedMenuDiv: "hasIssue"
        }
    ];
    vocabConfigs.forEach(config => {
        vocabs(config.dropdownId, config.inputBoxName, config.apiUrl, config.issuedMenuDiv);
    });
    //fetchSkenerData();    
    console.log("Beep beep, this is THE DEPOSIT form.");
  } else {
    console.log("Beep beep, this is javascript reporting.");
  }
  if (isFrontScroll) {
    headerScroll(isFrontScroll);
  }
});
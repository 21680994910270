export async function vocabs(vocabDropdownId, vocabInputBoxName, apiVocabUrl, issuedMenuDiv) {
  const dropdown = document.getElementById(vocabDropdownId);
  const inputBox = document.querySelector(`input[name="${vocabInputBoxName}"]`);
  const issuedMenu = document.getElementById(issuedMenuDiv);

  // Function to fetch data from the API and populate the dropdown
  async function populateDropdown() {
      try {
          const response = await fetch(apiVocabUrl); // Replace with your API URL
          const data = await response.json();
          
          // Clear existing options
          dropdown.innerHTML = '';

          // Add a default disabled option
          const defaultOption = document.createElement('option');
          defaultOption.textContent = 'Select an option';
          defaultOption.disabled = true;
          defaultOption.selected = true;
          dropdown.appendChild(defaultOption);

          // Add an option to clear the selection
          const clearOption = document.createElement('option');
          clearOption.textContent = 'None';
          clearOption.value = '';
          dropdown.appendChild(clearOption);
          
          // Navigate through the nested JSON structure
          const hits = data.hits.hits;
          
          // Add new options from the API data
          hits.forEach(item => {
              const option = document.createElement('option');
              option.value = item.id; // Use the 'id' field for the value
              option.textContent = item.title.en; // Use the 'title.en' field for the display text
              dropdown.appendChild(option);
          });
          
          // Add event listener to update input fields when selection changes
          dropdown.addEventListener("change", copyValue);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  }
  
  // Function to copy the selected value to the input fields
  function copyValue() {
      const selectedValue = dropdown.options[dropdown.selectedIndex].value;
      const selectedTextContent = dropdown.options[dropdown.selectedIndex].textContent;
      inputBox.value = selectedTextContent;

      // Check if the selected value is 'serialPublications' and show an alert
      if (vocabDropdownId === 'enumerateDropdown' && selectedValue === 'serialPublications') {
        //console.log(issuedMenu);
        issuedMenu.classList.add('visible');
        //issuedMenu.style.visibility = "visible";
        //issuedMenu.style.height = "auto";
      } else {
        issuedMenu.classList.remove('visible');
        //issuedMenu.style.visibility = "hidden";
        //issuedMenu.style.height = "0";
      }
  }
  
  // Call the function to populate the dropdown
  populateDropdown();
}
